<template>
  <div class="caseDetail">
    <PageTitle ttl-en="CASE" ttl-jp="_想定課題" />
    <div class="inner">
      <p class="caseDetail__mv js-scroll">
        <img src="/genbashiko/img/case/case09/mv.jpg" alt="" />
      </p>
      <section class="caseDetail__outline">
        <h1 class="js-scroll">
          ワークに適した照明のピッチ間隔で
          <br />ゼブラ検査ができない
        </h1>
        <div class="caseDetail__outline__txt js-scroll">
          塗装工程でのゼブラ検査は、多くの現場で広く活用されている手法です。多くの場合、現場ごとの経験や既存のルールに基づいてレイアウトが検討されています。
          <br />しかし、最適な配置かどうかの検証をするにも、従来の検査用の照明システムでは、配置やピッチの改善が難しいという課題があります。
          <br />特に自動車の車体検査では、映りこむ照明のピッチ・輝度差・幅が不良の発見率や見逃し率に大きく影響するため、照明の配置は非常に重要です。
        </div>
        <div class="caseDetail__outline__cate js-scroll">
          <p>担当領域:</p>
          <ul>
            <li>
              <router-link
                class="category"
                :to="{ name: 'Case', query: { category: '新たに検査現場を構築したい' } }"
              >
                新たに検査現場を構築したい
              </router-link>
            </li>
            <li>
              <router-link
                class="category"
                :to="{ name: 'Case', query: { category: '既存の検査場を改修したい' } }"
              >
                既存の検査場を改修したい
              </router-link>
            </li>
            <li>
              <router-link
                class="category"
                :to="{ name: 'Case', query: { category: '現状の課題を確認したい' } }"
              >
                現状の課題を確認したい
              </router-link>
            </li>
            <li>
              <router-link
                class="category"
                :to="{ name: 'Case', query: { category: '塗装完成車工程' } }"
              >
                塗装完成車工程
              </router-link>
            </li>
            <li>
              <router-link
                class="category"
                :to="{ name: 'Case', query: { category: 'ボデー・プレス工程' } }"
              >
                ボデー・プレス工程
              </router-link>
            </li>
          </ul>
        </div>
      </section>
      <section class="caseDetail__env">
        <h3 class="caseDetail__env__ttl js-scroll">想定される環境</h3>
        <ul class="js-scroll">
          <li>塗装後外観検査</li>
        </ul>
      </section>
      <section class="caseDetail__ex">
        <h3 class="caseDetail__ex__ttl js-scroll">想定課題と具体的な改善施策例</h3>
        <div class="caseDetail__ex__problem js-scroll">
          <div class="caseDetail__ex__problem__p">
            <p class="ttl">課題1</p>
            <p class="txt">
              適切な照明ピッチが分からない<br />検査環境は変わらないのに、検査対象が変わった
            </p>
          </div>
          <div class="caseDetail__ex__problem__a">
            <span class="ttl">施策1</span>
            <p class="txt">ピッチ変更できる照明で最適なピッチ幅を検討</p>
          </div>
          <div class="caseDetail__ex__problem__img">
            <p>
              <img src="/genbashiko/img/case/case09/image01.jpg" alt="" />
            </p>
          </div>
          <p class="caseDetail__ex__problem__cap">
            ライティングレール仕様でピッチ変更が可能な照明「ルーチ・ゼブライン」を導入し、最適なピッチ幅を検討。
            <br />ワンタッチで照明の取り付け・取り外しが可能なので、工事後でも簡単にピッチやレイアウトを変更できます。これにより、照明工事や部材の費用削減につながります。
          </p>
          <div class="caseDetail__ex__movie single js-scroll">
            <div class="caseDetail__ex__movie__block">
              <p class="caseDetail__ex__movie__ttl">▼商品ムービー</p>
              <div class="caseDetail__ex__movie__iframeWrap">
                <iframe
                  src="https://www.youtube.com/embed/VABzGD1rC1w?si=w8zryzjRdpRHzkgQ"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
          <p class="caseDetail__ex__download js-scroll">
            <a href="/genbashiko/upload/download_pdf/PA-LZL.pdf" target="_blank"
              >課題に関する資料ダウンロード</a
            >
          </p>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import PageTitle from "@/components/PageTitle.vue";

export default {
  components: {
    PageTitle,
  },
  head: {
    title: {
      inner: "Case09",
    },
    meta: [
      { property: "og:title", content: "Case09｜GENBA SHIKO" },
      {
        name: "description",
        content:
          "ワークに適した照明のピッチ間隔でゼブラ検査ができない方へ、ピッチ変更できる照明の導入で最適なピッチ幅を検討だけでなく費用削減も実現します。",
      },
      {
        property: "og:description",
        content:
          "ワークに適した照明のピッチ間隔でゼブラ検査ができない方へ、ピッチ変更できる照明の導入で最適なピッチ幅を検討だけでなく費用削減も実現します。",
      },
      {
        property: "og:url",
        content: "/genbashiko/case/case09",
      },
      { property: "og:type", content: "article" },
    ],
  },
};
</script>
